<template>
  <div>
    <div
      class="event-board"
      style="text-align: center;"
    >
      <div
        class="event-checked"
        style="position:absolute; left:0; top:0; width: 1000px; height: 500px;"
      >
        <div
          class="flower1"
          style="position: absolute; left:404px; top:127px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep1 >= 1"
              src="@/assets/images/events/event240320/completion-1.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep1)"
              src="@/assets/images/events/event240320/fail-1.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(1)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-1.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(1)"
            >
          </a>
        </div>
        <div
          class="flower2"
          style="position: absolute; left:480px; top:127px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep1 >= 2"
              src="@/assets/images/events/event240320/completion-2.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep1)"
              src="@/assets/images/events/event240320/fail-2.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(1)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-2.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(1)"
            >
          </a>
        </div>
        <div
          class="flower3"
          style="position: absolute; left:563px; top:127px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep1 >= 3"
              src="@/assets/images/events/event240320/completion-3.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep1)"
              src="@/assets/images/events/event240320/fail-3.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(1)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-3.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(1)"
            >
          </a>
        </div>
        <div
          class="flower4"
          style="position: absolute; left:659px; top:127px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep2 >= 1"
              src="@/assets/images/events/event240320/completion-4.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep2)"
              src="@/assets/images/events/event240320/fail-4.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(2)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-4.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(2)"
            >
          </a>
        </div>
        <div
          class="flower5"
          style="position: absolute; left:738px; top:127px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep2 >= 2"
              src="@/assets/images/events/event240320/completion-5.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep2)"
              src="@/assets/images/events/event240320/fail-5.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(2)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-5.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(2)"
            >
          </a>
        </div>
        <div
          class="flower6"
          style="position: absolute; left:813px; top:127px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep2 >= 3"
              src="@/assets/images/events/event240320/completion-6.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep2)"
              src="@/assets/images/events/event240320/fail-6.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(2)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-6.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(2)"
            >
          </a>
        </div>
        <div
          class="flower7"
          style="position: absolute; left:774px; top:307px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep3 >= 1"
              src="@/assets/images/events/event240320/completion-7.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep3)"
              src="@/assets/images/events/event240320/fail-7.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(3)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-7.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(3)"
            >
          </a>
        </div>
        <div
          class="flower8"
          style="position: absolute; left:658px; top:307px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep3 >= 2"
              src="@/assets/images/events/event240320/completion-8.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep3)"
              src="@/assets/images/events/event240320/fail-8.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(3)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-8.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(3)"
            >
          </a>
        </div>
        <div
          class="flower9"
          style="position: absolute; left:546px; top:307px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep3 >= 3"
              src="@/assets/images/events/event240320/completion-9.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0;"
            >
            <img
              v-else-if="checkDateEnd(endDateStep3)"
              src="@/assets/images/events/event240320/fail-9.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(3)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-9.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0"
              @click.stop="createEventHistory(3)"
            >
          </a>
        </div>
        <div
          class="flower10"
          style="position: absolute; left:423px; top:289px;"
        >
          <a
            href="#none"
            style="position: relative; left: 0; top: 0"
          >
            <img
              v-if="historyCountStep3 >= 4"
              src="@/assets/images/events/event240320/completion-10.png"
              alt="적립후"
              style="position: absolute; left: 0; top:0"
            >
            <img
              v-else-if="checkDateEnd(endDateStep3)"
              src="@/assets/images/events/event240320/fail-10.png"
              alt="적립실패"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(3)"
            >
            <img
              v-else
              src="@/assets/images/events/event240320/before-10.png"
              alt="적립전"
              style="position: absolute; left: 0; top:0;"
              @click.stop="createEventHistory(3)"
            >
          </a>
        </div>
      </div>
      <!-- 배경 -->
      <img
        src="@/assets/images/events/event240320/bg_event_stamp_empty.jpg"
        alt="봄꽃맞이 메디컬 트렌드 이벤트"
      >
    </div>
  </div>
</template>
<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import { numberFormatter } from '@/libs/utils/filters'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      today: dayjs().format('YYYY-MM-DD'),
      completeEvent: false,
      historyCount: 0,
      isProcessing: false,
      startDateStep1: '2024-04-02',
      startDateStep2: '2024-04-12',
      startDateStep3: '2024-04-23',
      endDateStep1: '2024-04-11',
      endDateStep2: '2024-04-22',
      endDateStep3: '2024-04-30',
      historyCountStep1: 0,
      historyCountStep2: 0,
      historyCountStep3: 0,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName() {
      if (this.userName) this.fetchEventHistory()
      if (this.userName) this.fetchEventComplete()
    },
  },
  created() {
    if (this.userName) this.fetchEventHistory()
    if (this.userName) this.fetchEventComplete()
  },
  methods: {
    checkDateEnd(toDate) {
      return toDate < this.today
    },
    fetchEventHistory() {
      axios.get(`/fu/event/hiscount/${this.eventId}`, {
        params: {
          isRewardFin: true
        },
      })
        .then(rs => {
          this.historyCount = rs.data
        }).catch(() => {})

      if (this.today >= this.startDateStep1) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep1}/${this.endDateStep1}`, {
          params: {
            isRewardFin: true
          },
        })
          .then(rs => {
            this.historyCountStep1 = rs.data
          }).catch(() => {})
      }
      if (this.today >= this.startDateStep2) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep2}/${this.endDateStep2}`, {
          params: {
            isRewardFin: true
          },
        })
          .then(rs => {
            this.historyCountStep2 = rs.data
          }).catch(() => {})
      }
      if (this.today >= this.startDateStep3) {
        axios.get(`/fu/event/hiscount/${this.eventId}/${this.startDateStep3}/${this.endDateStep3}`, {
          params: {
            isRewardFin: true
          },
        })
          .then(rs => {
            this.historyCountStep3 = rs.data
          }).catch(() => {})
      }
    },
    fetchEventComplete() {
      axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`, {
        params: {
          isRewardFin: true
        },
      })
        .then(rs => {
          if (rs.data) {
            this.completeEvent = true
          } else this.completeEvent = false
        })
        .catch(() => {
        })
    },
    createEventHistory(step) {
      if (this.userName) {
        if (this.isProcessing) return

        if (this.completeEvent) {
          this.$parent.showAlertDialog('이미 참여한 컨텐츠 입니다.')
          return
        }
        if (this.historyCount >= 10) {
          this.$parent.showAlertDialog('참여 가능한 출석체크를 모두 완료하셨습니다.')
          return
        }
        if (step === 1) {
          if (this.historyCountStep1 >= 3) {
            this.$parent.showAlertDialog('기간 내 참여 가능 회차를 모두 완료하였습니다.')
            return
          } if (this.today < this.startDateStep1) {
            this.$parent.showAlertDialog('4월 2일부터 참여 가능합니다.')
            return
          } if (this.today > this.endDateStep1) {
            this.$parent.showAlertDialog('4월 11일까지 참여 가능합니다.')
            return
          }
        }
        if (step === 2) {
          if (this.historyCountStep2 >= 3) {
            this.$parent.showAlertDialog('기간 내 참여 가능 회차를 모두 완료하였습니다.')
            return
          } if (this.today < this.startDateStep2) {
            this.$parent.showAlertDialog('4월 12일부터 참여 가능합니다.')
            return
          } if (this.today > this.endDateStep2) {
            this.$parent.showAlertDialog('4월 22일까지 참여 가능합니다.')
            return
          }
        }
        if (step === 3) {
          if (this.historyCountStep3 >= 4) {
            this.$parent.showAlertDialog('기간 내 참여 가능 회차를 모두 완료하였습니다.')
            return
          } if (this.today < this.startDateStep3) {
            this.$parent.showAlertDialog('4월 23일부터 참여 가능합니다.')
            return
          } if (this.today > this.endDateStep3) {
            this.$parent.showAlertDialog('4월 30일부터 참여 가능합니다.')
            return
          }
        }

        // processing on
        this.isProcessing = true

        this.$parent.showConfirmDialog('출석 체크 하시겠습니까?', (result) => {
          if (result) {
            axios.post('/fu/event/history-reward', {
              EventId: this.eventId,
              ContentId: this.contentId,
            })
              .then(rs => {
                setStatistics(121, this.eventId)

                if (rs.data && rs.data.pointBalance) changeUserPoint(rs.data.pointBalance)

                this.fetchEventHistory()

                this.$parent.showAlertDialog(`이벤트 참여 완료하여 ${numberFormatter(rs.data.rewardPoint)}포인트가 지급되었습니다.`, () => {
                  this.isProcessing = false
                  this.completeEvent = true
                })
              })
              .catch(() => {
                this.isProcessing = false

                this.$parent.showAlertDialog('이벤트 처리 중 오류가 발생하였습니다.')
              })
          } else {
            this.isProcessing = false
          }
        })
      } else {
        this.isProcessing = false

        this.redirectLogin()
      }
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
  }
}
</script>

<style scoped>
  body {
      margin: 0;
      padding: 0;
  }
  img {
      display: block;
      margin: 0 auto;
  }
  area:hover {
      cursor: pointer;
  }
  .event-board {
      width: 1000px;
      margin: 0 auto;
      position: relative;
      text-align: center;
  }
</style>
