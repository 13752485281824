var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "event-board", staticStyle: { "text-align": "center" } },
      [
        _c(
          "div",
          {
            staticClass: "event-checked",
            staticStyle: {
              position: "absolute",
              left: "0",
              top: "0",
              width: "1000px",
              height: "500px",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "flower1",
                staticStyle: {
                  position: "absolute",
                  left: "404px",
                  top: "127px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep1 >= 1
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-1.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep1)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-1.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(1)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-1.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(1)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower2",
                staticStyle: {
                  position: "absolute",
                  left: "480px",
                  top: "127px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep1 >= 2
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-2.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep1)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-2.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(1)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-2.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(1)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower3",
                staticStyle: {
                  position: "absolute",
                  left: "563px",
                  top: "127px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep1 >= 3
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-3.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep1)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-3.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(1)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-3.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(1)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower4",
                staticStyle: {
                  position: "absolute",
                  left: "659px",
                  top: "127px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep2 >= 1
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-4.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep2)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-4.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(2)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-4.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(2)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower5",
                staticStyle: {
                  position: "absolute",
                  left: "738px",
                  top: "127px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep2 >= 2
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-5.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep2)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-5.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(2)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-5.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(2)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower6",
                staticStyle: {
                  position: "absolute",
                  left: "813px",
                  top: "127px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep2 >= 3
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-6.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep2)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-6.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(2)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-6.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(2)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower7",
                staticStyle: {
                  position: "absolute",
                  left: "774px",
                  top: "307px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep3 >= 1
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-7.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep3)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-7.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-7.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower8",
                staticStyle: {
                  position: "absolute",
                  left: "658px",
                  top: "307px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep3 >= 2
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-8.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep3)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-8.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-8.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower9",
                staticStyle: {
                  position: "absolute",
                  left: "546px",
                  top: "307px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep3 >= 3
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-9.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep3)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-9.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-9.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flower10",
                staticStyle: {
                  position: "absolute",
                  left: "423px",
                  top: "289px",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { position: "relative", left: "0", top: "0" },
                    attrs: { href: "#none" },
                  },
                  [
                    _vm.historyCountStep3 >= 4
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/completion-10.png"),
                            alt: "적립후",
                          },
                        })
                      : _vm.checkDateEnd(_vm.endDateStep3)
                      ? _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/fail-10.png"),
                            alt: "적립실패",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                          },
                          attrs: {
                            src: require("@/assets/images/events/event240320/before-10.png"),
                            alt: "적립전",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.createEventHistory(3)
                            },
                          },
                        }),
                  ]
                ),
              ]
            ),
          ]
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/images/events/event240320/bg_event_stamp_empty.jpg"),
            alt: "봄꽃맞이 메디컬 트렌드 이벤트",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }